export const navLinks = [
  {
    id: 1,
    title: "Home",
    path: "/",
    cName: "nav-link",
  },
  {
    id: 2,
    title: "About",
    path: "/about-us",
    cName: "nav-link",
  },
  {
    id: 3,
    title: "Services",
    cName: "nav-link service-link",
  },
  {
    id: 4,
    title: "Contact",
    path: "/contact-us",
    cName: "nav-link",
  },
];

export const subItems = [
  {
    id: 1,
    title: "Web Development",
    path: "/services/web-development",
    cName: "sub-link",
  },
  {
    id: 2,
    title: "UI/UX Design",
    path: "/services/ui-ux-design",
    cName: "sub-link",
  },
  {
    id: 3,
    title: "Mobile Development",
    path: "/services/mobile-development",
    cName: "sub-link",
  },
  {
    id: 4,
    title: "Software Development",
    path: "/services/software-development",
    cName: "sub-link",
  },
];
