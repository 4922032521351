import React from "react";
import { Link } from "react-router-dom";
import "./IconElement.css";

const IconElement = ({ urlIcon, urlPath }) => {
  return (
      <Link to={urlPath} target="_blank" className="social-icon-link">
        {urlIcon}
      </Link>
  );
};

export default IconElement;
