import React from "react";

const ContactInfo = () => {
  return (
    <div className="footer-container contact-info">
      <h4 className="footer-title-sm">Contact Information</h4>
      <div className="links-div">
        <div className="contact-info-div">
          <strong>Phone</strong>
          <p className="footer-desc p-tag">0727637947</p>
        </div>
        <div className="contact-info-div">
          <strong>Address</strong>
          <p className="footer-desc p-tag">Nairobi, Kenya.</p>
        </div>
        <div className="contact-info-div">
          <strong>Email</strong>
          <p className="footer-desc p-tag">alphamaxsoftwares@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
