// navbar.js
import React, { useState, useEffect } from "react";
import { navLinks, subItems } from "./NavItems";
import { Link, useLocation } from "react-router-dom";
import logoImage from "../../static/imgs/logoImage.png";
import { IoMenu, IoCloseOutline } from "react-icons/io5";
import { FaAngleLeft } from "react-icons/fa6";
import {
  FaGithub,
  FaFacebookF,
  FaLinkedinIn,
  FaAngleUp,
  FaAngleDown,
  FaAngleRight,
} from "react-icons/fa";
import "./NavBar.css";
import IconElement from "../icons/IconElement";

const NavBar = () => {
  const [isClicked, setIsClicked] = useState(false);
  const location = useLocation();
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 767);
  const [isToggled, setIsToggled] = useState(true);
  const [currentPath, setCurrentPath] = useState(location.pathname); // Add state to hold current path
  const [isInService, setIsInService] = useState(false)

  const handleToggle = (e) => {
    e.preventDefault();
    setIsToggled(!isToggled);
    if (!isWideScreen) {
      const navigation = document.getElementById("Navigation");
      if (!isToggled) {
        navigation.style.display = "none";
      } else {
        navigation.style.display = "flex";
      }
    }
  };
  const handleClick = () => {
    setIsClicked(!isClicked);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 767);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsClicked(false);
    if (!isWideScreen) {
      const navigation = document.getElementById("Navigation");
      navigation.style.display = "none";
      setIsToggled(true);
    } else {
      const navigation = document.getElementById("Navigation");
      navigation.style.display = "flex";
    }
  }, [location, isWideScreen]);

  useEffect(() => {
    setCurrentPath(location.pathname); // Update current path when location changes
    setIsInService(location.pathname.startsWith('/services'));
  }, [location.pathname]);

  return (
    <header>
      <nav id="Navigation">
        <figure className="company-logo">
          <Link to="/" className="logo-link">
            <img
              src={logoImage}
              alt={`AlphaMax Software International`}
              className="logo-img"
              loading="eager"
              title="AlphaMax Software International"
            />
          </Link>
        </figure>
        {isWideScreen ? (
          // The navigation bar for the wider screen
          <React.Fragment>
            <ul className="nav-links">
              {navLinks.map(({ id, cName, title, path }) => (
                <li key={id} className={cName}>
                  {title === "Services" ? (
                    <React.Fragment>
                      <span onClick={handleClick} className={`link ${isInService ? 'curr-page' : 'service-link'}`}>
                        {title} {isClicked ? <FaAngleUp /> : <FaAngleDown />}
                      </span>
                      {isClicked && (
                        <ul className="sub-links">
                          {subItems.map(({ id, cName, title, path }) => (
                            <li key={id} className={cName}>
                              <Link
                                to={path}
                                className={`link ${
                                  path === currentPath ? "curr-page" : ""
                                }`}
                              >
                                {title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </React.Fragment>
                  ) : (
                    <Link
                      to={path}
                      className={`link ${
                        path === currentPath ? "curr-page" : ""
                      }`}
                    >
                      {title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </React.Fragment>
        ) : (
          // The navigation bar for the smaller screen
          <React.Fragment>
            {isClicked ? (
              <React.Fragment>
                <div className="handler-div">
                  <button onClick={handleClick} className="service-handle">
                    <FaAngleLeft />
                    Services
                  </button>
                </div>
                <ul className="sub-links">
                  {subItems.map(({ id, cName, title, path }) => (
                    <li key={id} className={cName}>
                      <Link
                        to={path}
                        className={`link ${
                          path === currentPath ? "curr-page" : ""
                        }`}
                      >
                        {title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <ul className="nav-links">
                  {navLinks.map(({ id, cName, title, path }) => (
                    <li key={id} className={cName}>
                      {title === "Services" ? (
                        <span
                          onClick={handleClick}
                          className={`link ${isInService ? 'curr-page' : 'service-link'}`}
                        >
                          {title}
                          {isClicked ? "" : <FaAngleRight />}
                        </span>
                      ) : (
                        <Link
                          to={path}
                          className={`link ${
                            path === currentPath ? "curr-page" : ""
                          }`}
                        >
                          {title}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            )}
            <React.Fragment>
              <hr className="horizon" />
              <div className="social-icon-div">
                <IconElement
                  urlIcon={<FaGithub />}
                  urlPath="https://github.com/bartai5/"
                />
                <IconElement
                  urlIcon={<FaFacebookF />}
                  urlPath="https://www.facebook.com/alphamaxsoftwares/"
                />
                <IconElement
                  urlPath="https://www.linkedin.com/in/alphamax-software-international/"
                  urlIcon={<FaLinkedinIn />}
                />
              </div>
            </React.Fragment>
          </React.Fragment>
        )}
        <div className="cta-button-div">
          <Link className="cta-btn" to={'/contact-us'}>Get a Quote</Link>
        </div>
      </nav>
      <div className="toggle-div">
        <button className="toggle-btn" onClick={handleToggle}>
          {isToggled ? <IoMenu /> : <IoCloseOutline />}
        </button>
      </div>
    </header>
  );
};

export default NavBar;
