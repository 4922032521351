import React from "react";

const CompDesc = () => {
  return (
    <div className="footer-container comp-desc">
      <h2 className="footer-title">AlphaMax Software International</h2>
      <p className="footer-desc">
        Welcome to AlphaMax Software International, where our commitment to 'In
        code we trust' fuels every project. As your premier destination for
        software development, we specialize in crafting innovative web solutions
        tailored specifically for global businesses. With a team of seasoned
        experts, we deliver top-notch software solutions that enhance efficiency
        and productivity. Whether you require web applications, e-commerce
        platforms, or enterprise solutions, AlphaMax is your trusted partner.
        Let's collaborate and innovate together!
      </p>
    </div>
  );
};

export default CompDesc;
