import React from "react";
import { SiGmail } from "react-icons/si";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaGithub,
  FaWhatsapp,
} from "react-icons/fa";
import IconElement from "../icons/IconElement";

const Copyright = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="copyright">
      <p className="copyright-text">
        &#169;{" "}
        <Link to="mailto:alphamaxsoftwares@gmail.com" className="foo-link">
          AlphaMax Software International
        </Link>{" "}
        | All Right Reserved 2023 - {currentYear}.
      </p>
      <div className="social-icon-div">
        <IconElement
          urlIcon={<FaGithub />}
          urlPath="https://github.com/bartai5/"
        />
        <IconElement
          urlIcon={<FaFacebookF/>}
          urlPath="https://www.facebook.com/alphamaxsoftwares/"
        />
       <IconElement
          urlPath="https://www.linkedin.com/in/alphamax-software-international/"
          urlIcon={<FaLinkedinIn />}
        />
        <IconElement
          urlPath="https://wa.me/254727637947"
          urlIcon={<FaWhatsapp />}
        />
        <IconElement
          urlPath="mailto:alphamaxsoftwares@gmail.com"
          urlIcon={<SiGmail />}
        />
      </div>
    </div>
  );
};

export default Copyright;
