import React, { lazy, Suspense, useState, useEffect } from "react";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./components/navbar/NavBar";
import Footer from "./components/footer/Footer";

import ReactGa from 'react-ga';

const Home = lazy(() => import("./pages/home/Home"));
const About = lazy(() => import("./pages/about/About"));
const Contact = lazy(() => import("./pages/contact/Contact"));

const WebDev = lazy(() => import("./pages/services/web-dev/WebDev"));
const UxUiDesign = lazy(() => import("./pages/services/uxui-dev/UxUiDesign"));
const MobileDev = lazy(() => import("./pages/services/mobile-dev/MobileDev"));
const SoftwareDev = lazy(() =>
  import("./pages/services/software-dev/SoftwareDev")
);

const Error404 = lazy(() => import("./pages/not-found/NotFound"));

// Google Analytics
const TRACKING_ID = "G-BP4N3RLX4N";
ReactGa.initialize(TRACKING_ID);

function App() {
  const [isValidUrl, setIsValidUrl] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    const validUrls = [
      "/",
      "/about-us",
      "/contact-us",
      "/services/web-development",
      "/services/ui-ux-design",
      "/services/mobile-development",
      "/services/software-development",
    ];
  
    const currentLocation = location.pathname;
    if (validUrls.includes(currentLocation)) {
      setIsValidUrl(true);
    } else {
      setIsValidUrl(false);
    }
  }, [location.pathname]);

  return (
    <div className="App">
      {isValidUrl ? <NavBar/> : null}
      <Suspense
        fallback={
          <div className="fallback-div">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 150">
              <path
                fill="none"
                stroke="#f34f3a"
                stroke-width="15"
                stroke-linecap="round"
                stroke-dasharray="300 385"
                stroke-dashoffset="0"
                d="M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z"
              >
                <animate
                  attributeName="stroke-dashoffset"
                  calcMode="spline"
                  dur="2"
                  values="685;-685"
                  keySplines="0 0 1 1"
                  repeatCount="indefinite"
                ></animate>
              </path>
            </svg>
            <h3 className="loading-fallback">Loading...</h3>
          </div>
        }
      >
        <section>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/contact-us" element={<Contact />} />

            <Route path="/services/web-development" element={<WebDev />} />
            <Route path="/services/ui-ux-design" element={<UxUiDesign />} />
            <Route
              path="/services/mobile-development"
              element={<MobileDev />}
            />
            <Route
              path="/services/software-development"
              element={<SoftwareDev />}
            />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </section>
        {isValidUrl ? <Footer/> : null}
      </Suspense>
    </div>
  );
}

export default App;
