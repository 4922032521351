import React from "react";
import { FaChevronRight } from "react-icons/fa";
import LinkElement from "../link_tags/LinkElement";

const UsefullLinks = () => {
  return (
    <div className="footer-container usefull-links">
      <h3 className="footer-title-sm">Quick Links</h3>
      <div className="links-div">
        <LinkElement
          linkName="Home"
          linkUrl="/"
          urlTarget="_self"
          linkIcon={<FaChevronRight />}
        />
        <LinkElement
          linkName="About Us"
          linkUrl="/about-us"
          urlTarget="_self"
          linkIcon={<FaChevronRight />}
        />
        <LinkElement
          linkName="Contact Us"
          linkUrl="/contact-us"
          urlTarget="_self"
          linkIcon={<FaChevronRight />}
        />
      </div>
    </div>
  );
};

export default UsefullLinks;
