import React from "react";
import { FaChevronRight } from "react-icons/fa";
import LinkElement from "../link_tags/LinkElement";

const OurServices = () => {
  return (
    <div className="footer-container our-services">
      <h4 className="footer-title-sm">Our Services</h4>
      <div className="links-div">
        <LinkElement
          linkName="Web Development"
          linkUrl="/services/web-development"
          urlTarget="_self"
          linkIcon={<FaChevronRight />}
        />
        <LinkElement
          linkName="UI/UX Design"
          linkUrl="/services/ui-ux-design"
          urlTarget="_self"
          linkIcon={<FaChevronRight />}
        />
        <LinkElement
          linkName="Mobile Development"
          linkUrl="/services/mobile-development"
          urlTarget="_self"
          linkIcon={<FaChevronRight />}
        />
        <LinkElement
          linkName="Software Development"
          linkUrl="/services/software-development"
          urlTarget="_self"
          linkIcon={<FaChevronRight />}
        />
      </div>
    </div>
  );
};

export default OurServices;
