import React from "react";
import "./Footer.css";
import Copyright from "./Copyright";
import CompDesc from "./CompDesc";
import OurServices from "./OurServices";
import UsefullLinks from "./UsefullLinks";
import ContactInfo from "./ContactInfo";

const Footer = () => {
  return (
    <section id="Footer" className="footer-section">
      <CompDesc />
      <UsefullLinks />
      <OurServices />
      <ContactInfo />
      <Copyright />
    </section>
  );
};

export default Footer;
