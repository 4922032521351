import React from 'react'
import { Link } from 'react-router-dom';

const LinkElement = ({ linkName, linkUrl, urlTarget, linkIcon }) => {
  return (
    <Link to={`${linkUrl}`} target={urlTarget} className='link-element'>
        {linkIcon}
        {linkName}
    </Link>
  )
}

export default LinkElement
